import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { FittingElement } from "../../components/hoseCardElements/FittingElement";
import { HoseElement } from "../../components/hoseCardElements/HoseElement";
import { SleeveElement } from "../../components/hoseCardElements/SleeveElement";
import { HoseImage } from "../../components/hoseImage/HoseImage";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getHoseByIdAction } from "../../redux/actions/hoses.action";
import { CoverElement } from "../../components/hoseCardElements/CoverElement";
interface LocationState {
    backgroundLocation?: any;
    quantity?: number;
}
export const SingleHoseModalRoute = () => {
    const { hoseId } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const quantity = (location.state as LocationState)?.quantity;
    const { singleHose, loading } = useSelector((item: any) => item.hoses);
    useEffect(() => {
        dispatch(getHoseByIdAction(hoseId));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoseId]);

    return (
        <Background onClick={() => navigate(-1)}>
            {loading === "idle" && singleHose && (
                <Wrapper onClick={(e) => e.stopPropagation()}>
                    <TopWrap>
                        <TopLeft>
                            <GoBack onClick={() => navigate(-1)}>{"< Wróć"}</GoBack>
                        </TopLeft>

                        {singleHose?.ID && (
                            <QrCodeWrap>
                                <QRCode level="L" value={singleHose?.qrCode} size={80} />
                                <Title style={{ marginTop: 4 }}>{singleHose?.qrCode}</Title>
                            </QrCodeWrap>
                        )}
                    </TopWrap>
                    <HoseImage
                        leftFitting={singleHose?.leftFittingDetails}
                        rightFitting={singleHose?.rightFittingDetails}
                    />
                    <Row>
                        <ElementTitle>Dodatkowa Plakietka?</ElementTitle>
                        <ElementValue>{singleHose?.additionalLabel?.length ? singleHose?.additionalLabel : 'brak'}</ElementValue>
                    </Row>
                    <Title>Podstawowe dane</Title>
                    <Row>
                        <ElementTitle>Ilość</ElementTitle>
                        <ElementValue>{quantity}</ElementValue>
                    </Row>
                    <Row>
                        <ElementTitle>Długość</ElementTitle>
                        <ElementValue>{singleHose?.length + " cm"}</ElementValue>
                    </Row>
                    <Row>
                        <ElementTitle>Orientacja zakończeń</ElementTitle>
                        <ElementValue>{singleHose?.ending + "°"}</ElementValue>
                    </Row>
                    <HoseElement hoseDetails={singleHose?.hoseDetails} />
                    <SleeveElement sleeveDetails={singleHose?.sleeveDetails} />
                    <FittingElement fittingDetails={singleHose?.leftFittingDetails} title={"Lewe zakucie"} />
                    <FittingElement fittingDetails={singleHose?.rightFittingDetails} title={"Prawe zakucie"} />
                    {singleHose?.coverDetails && (
                        <CoverElement coverDetails={singleHose?.coverDetails} title={"Owijka"} />
                    )}
                </Wrapper>
            )}
        </Background>
    );
};

const TopLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const TopWrap = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;
const GoBack = styled.button`
    padding: 8px 16px;
    background-color: ${({ theme }) => theme.color.app_blue};
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    &:hover {
        opacity: 0.8;
    }
`;
const QrCodeWrap = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ElementValue = styled.div`
    height: 30px;
    width: 300px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 14px;
`;
const ElementTitle = styled.div`
    height: 30px;
    width: 200px;
    border-right: 1px solid black;
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 14px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    border: 1px solid black;
`;
const Title = styled.p`
    margin: 15px 0 5px 0;
    font-size: 16px;
`;
const Wrapper = styled.div`
    position: relative;
    padding: 30px;
    margin-top: 50px;
    width: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
`;
const Background = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: rgba(107, 112, 121, 0.3);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    top: 0;
    left: 0;
    position: fixed;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
