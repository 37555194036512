import { useEffect } from "react";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { OrdersRow } from "../../components/elements/OrdersRow";
import { ScreenWrapper } from "../../components/wrappers/ScreenWrapper";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getIncompletedOrdersAndFilter, getOrdersAction } from "../../redux/actions/orders.action";
import { theme } from "../../constants/theme";
export const DashBoardRoute = () => {
    const dispatch = useAppDispatch();
    const { orders, loading } = useSelector((state: any) => state.orders);
    const { ordersIncompletedFiltered } = useSelector((state: any) => state.orders);
    const getOrders = async () => {
        await dispatch(getOrdersAction("pending"));
        await dispatch(getIncompletedOrdersAndFilter());
    };

    useEffect(() => {
        getOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <ScreenWrapper>
            <Wrapper>
                <MoonLoader color={"black"} loading={loading === "pending"} size={30} speedMultiplier={0.4} />
                <ListHeader>- Do realizacji</ListHeader>
                <HeaderRow>
                    <RowElement style={{ width: 50 }}>Nr</RowElement>
                    <RowElement>Klient</RowElement>
                    <RowElement>Status</RowElement>
                    <RowElement>Płatność</RowElement>
                    <RowElement>Data</RowElement>
                </HeaderRow>
                <Wrapper style={{ overflow: "scroll", paddingBottom: 100, height: "40vh" }}>
                    {orders?.map((item: any, index: any) => (
                        <OrdersRow key={index} data={item} />
                    ))}
                </Wrapper>
                <ListHeader style={{ backgroundColor: theme.colors.app_green }}>
                    - Nieopłacone (proforma lub oferta)
                </ListHeader>
                <HeaderRow>
                    <RowElement style={{ width: 50 }}>Nr</RowElement>
                    <RowElement>Klient</RowElement>
                    <RowElement>Status</RowElement>
                    <RowElement>Płatność</RowElement>
                    <RowElement>Data</RowElement>
                </HeaderRow>
                <Wrapper style={{ overflow: "scroll", paddingBottom: 100, height: "35vh" }}>
                    {ordersIncompletedFiltered
                        ?.slice()
                        .reverse()
                        .map((item: any, index: any) => (
                            <OrdersRow key={index} data={item} />
                        ))}
                </Wrapper>
            </Wrapper>
        </ScreenWrapper>
    );
};

const ListHeader = styled.div`
    width: 100%;
    padding: 5px 20px;
    font-size: 16px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.color.app_blue};
    border-bottom: 1px solid ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.colors.white};
`;
const RowElement = styled.div`
    width: 20%;
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.black};
`;
const HeaderRow = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px 0;
    padding-left: 20px;
    text-decoration: none;
    background-color: ${({ theme }) => theme.color.app_lightBlue};
`;
const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 0;
`;
