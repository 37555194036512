import { createSlice } from "@reduxjs/toolkit";
import { jsonParse } from "../../utils/jsonParse.utils";
import {
    changeOrderStatusAction,
    getIncompletedOrdersAndFilter,
    getOrdersAction,
    getSingleOrderAction,
} from "../actions/orders.action";

interface ordersSliceTypes {
    loading: any;
    orders: Array<any>;
    ordersIncompletedFiltered: Array<any>;
    singleOrder: any;
}

const initialState: ordersSliceTypes = {
    loading: "idle",
    orders: [],
    ordersIncompletedFiltered: [],
    singleOrder: [],
};

export const ordersSlice = createSlice({
    name: "orders",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getIncompletedOrdersAndFilter.fulfilled, (state: ordersSliceTypes, action: any) => {
                state.ordersIncompletedFiltered = action.payload;
                state.loading = "idle";
            })
            .addCase(getOrdersAction.fulfilled, (state: ordersSliceTypes, action: any) => {
                state.orders = action.payload;
                state.loading = "idle";
            })
            .addCase(getOrdersAction.pending, (state: ordersSliceTypes) => {
                state.loading = "pending";
            })
            .addCase(getOrdersAction.rejected, (state: ordersSliceTypes) => {
                state.loading = "error";
            })
            .addCase(getSingleOrderAction.fulfilled, (state: ordersSliceTypes, action: any) => {
                const orderData = {
                    ...action.payload[0],
                    invoiceData: jsonParse(action.payload[0]?.invoiceData),
                    sendData: jsonParse(action.payload[0]?.sendData),
                    clientData: jsonParse(action.payload[0]?.clientData),
                };

                const mail = action.payload[1];
                const orderHoses = action.payload[2];
                const parsedData = { orderData: orderData, mail: mail, orderHoses: orderHoses };
                state.singleOrder = parsedData;
                state.loading = "idle";
            })
            .addCase(getSingleOrderAction.pending, (state: ordersSliceTypes) => {
                state.loading = "pending";
            })
            .addCase(getSingleOrderAction.rejected, (state: ordersSliceTypes) => {
                state.loading = "error";
            })
            .addCase(changeOrderStatusAction.fulfilled, (state: ordersSliceTypes) => {
                state.loading = "idle";
            })
            .addCase(changeOrderStatusAction.pending, (state: ordersSliceTypes) => {
                state.loading = "pending";
            })
            .addCase(changeOrderStatusAction.rejected, (state: ordersSliceTypes) => {
                state.loading = "error";
            });
    },
});
