import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import styled from "styled-components";
import { AppButtonRegular } from "../../components/buttons/AppButtonRegular";
import { HosesRow } from "../../components/elements/HosesRow";
import { useOrderStatus } from "../../hooks/useOrderStatus";
import { getSingleOrderAction } from "../../redux/actions/orders.action";
import { convertPriceToDisplayFormat } from "../../utils/parsePrice";
import { switchOrderStatus } from "../../utils/switchOrderStatus";
import { encryptOrderId } from "../../utils/crypto.utils";
type useParamsTypes = {
    id: string;
};
export const SingleOrderModalRoute = () => {
    const { id } = useParams<useParamsTypes>();
    const { singleOrder, loading } = useSelector((state: any) => state.orders);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { changeStatus } = useOrderStatus();
    useEffect(() => {
        dispatch(getSingleOrderAction(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const sendData = singleOrder?.orderData?.sendData;
    const invoiceData = singleOrder?.orderData?.invoiceData;
    const orderStatus = singleOrder?.orderData?.orderStatus;

    const changeOrderStatus = () => {
        if (orderStatus === "pending") {
            return changeStatus({ status: "in_progress", orderId: id, route: "/main/toSend" });
        } else if (orderStatus === "in_progress") {
            return changeStatus({ status: "finished", orderId: id, route: "/main/lastSend" });
        } else if (orderStatus === "incompleted") {
            return changeStatus({ status: "pending", orderId: id, route: "/main/dashboard" });
        }
    };
    const setButtonText = () => {
        if (orderStatus === "pending") {
            return "do wysyłki";
        } else if (orderStatus === "in_progress") {
            return "wysłane";
        } else if (orderStatus === "incompleted") {
            return "do realizacji";
        } else {
            return "zmień status";
        }
    };
    const submit = () => {
        confirmAlert({
            title: "Zmiana statusu",
            message:
                "Upewnij się, ze wszystkie przewody są zakute, posiadają plakietki z kodem QR, oraz zamówienie jest gotowe do wysyłki ",
            buttons: [
                {
                    label: "Zmień status",
                    onClick: () => changeOrderStatus(),
                },
                {
                    label: "Anuluj",
                },
            ],
        });
    };

    const env = process.env.REACT_APP_ENVIRONMENT;
    const todayDateAsNumber = Date.now();
    const orderString = encryptOrderId(Number(singleOrder?.orderData?.id));
    const konfiguratorUrl =
        env === "production"
            ? `http://konfigurator.zakuwanie24.pl/single-order/${orderString}_${todayDateAsNumber}`
            : `https://konfigurator-zakuwanie24-staging.netlify.app/single-order/${orderString}_${todayDateAsNumber}`;

    return (
        <Background onClick={() => navigate(-1)}>
            <Wrapper onClick={(e) => e.stopPropagation()}>
                <MoonLoader color={"black"} loading={loading === "pending"} size={30} speedMultiplier={0.4} />
                <TopWrap>
                    <InfoWrap>
                        <Left>
                            <OrderNumber>{"Zamówienie " + singleOrder?.orderData?.id}</OrderNumber>
                            <Orderdate>{singleOrder?.orderData?.orderDate}</Orderdate>
                            <Client>{singleOrder?.orderData?.clientData?.phone}</Client>
                            <Client>{singleOrder?.mail?.mail}</Client>
                            <Client style={{ marginTop: 15 }}>
                                Wartość: {convertPriceToDisplayFormat(singleOrder?.orderData?.price)} zł
                            </Client>
                            {/* To dodać jak w shipping bedzie wartość przesyłki */}
                            <Client>
                                W tym przesyłka {convertPriceToDisplayFormat(singleOrder?.orderData?.shipping)}zł
                            </Client>
                        </Left>
                        <Left>
                            <OrderNumber>Adres wysyłki</OrderNumber>
                            <Client>{sendData?.fname + " " + sendData?.lname}</Client>
                            <Client>{sendData?.address1}</Client>
                            <Client>{sendData?.address2}</Client>
                            <Client>{sendData?.mail}</Client>
                            <Client>{sendData?.phone}</Client>
                        </Left>
                        <Left>
                            <OrderNumber>Dane do faktury</OrderNumber>
                            <Client>{invoiceData?.nip}</Client>
                            <Client>{invoiceData?.companyName}</Client>
                            <Client>{invoiceData?.address1}</Client>
                            <Client>{invoiceData?.address2}</Client>
                        </Left>
                        <Left>
                            <OrderNumber>Komentarz klienta</OrderNumber>
                            <Client>{singleOrder?.orderData?.comment}</Client>
                        </Left>
                        <Left>
                            <OrderNumber>Informacja dla kuriera</OrderNumber>
                            <Client>{singleOrder?.orderData?.courierInfo}</Client>
                        </Left>
                    </InfoWrap>

                    <Right>
                        <StatusTitle>Status zamówienia</StatusTitle>
                        <Status>{switchOrderStatus(singleOrder?.orderData?.orderStatus)}</Status>
                        <StatusTitle>Zmień status na:</StatusTitle>
                        <AppButtonRegular title={setButtonText()} onClick={submit} />
                    </Right>
                </TopWrap>
                <a style={{ alignSelf: "flex-start", marginTop: 10, marginBottom: 0 }} href={konfiguratorUrl} target="_blank" rel="noreferrer">
                    <StatusTitle> Podgląd zamówienia po stronie klienta</StatusTitle>
                </a>
                <TableHeader>
                    <Element style={{ width: 40 }}>Id</Element>
                    <Element>Przewód</Element>
                    <Element>Lewe zakucie</Element>
                    <Element>Prawe zakucie</Element>
                    <Element>Owijka</Element>
                    <Element>Kod QR</Element>
                    <Element>Status</Element>
                    <Element style={{ width: 40 }}>Ilość</Element>
                </TableHeader>
                {singleOrder?.orderHoses?.map((item: any, index: any) => (
                    <HosesRow item={item} key={index} index={index} />
                ))}
            </Wrapper>
        </Background>
    );
};
const StatusTitle = styled.div`
    font-size: 12px;
    font-weight: 600;
`;
const Status = styled.div`
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 10px;
`;
const InfoWrap = styled.div`
    display: flex;
    flex-direction: row;
`;
const Element = styled.div`
    width: 20%;
    padding-left: 10px;
`;
const TableHeader = styled.div`
    text-decoration: none;
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
    height: 48px;
    padding: 0 20px;
    margin: 30px 0 10px 0;
    background-color: #95c8f850;
`;
const Client = styled.p`
    font-size: 12px;
    font-weight: 600;
    margin: 1px 0;
`;
const Orderdate = styled.p`
    font-size: 10px;
    font-weight: 500;
    margin: 5px 0;
`;
const OrderNumber = styled.p`
    font-size: 16px;
    font-weight: 700;
    margin: 0;
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    padding-right: 40px;
    border-right: 1px solid lightgrey;
`;
const TopWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;
const Wrapper = styled.div`
    /* position: absolute;
    left: 50%;
    transform: translate(-50%, 0); */
    padding: 30px;
    margin-top: 50px;
    width: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
`;
const Background = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: rgba(107, 112, 121, 0.3);
    backdrop-filter: blur(2px);
    transition: background-color 400ms ease-in-out;
    top: 0;
    left: 0;
    position: fixed;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
