import { createAsyncThunk } from "@reduxjs/toolkit";
import { changeOrderStatusService, fetchOrdersService, fetchSingleOrderService } from "../../services/orders.service";

export const getOrdersAction: any = createAsyncThunk("orders/getOrders", async (status: string) => {
    const response = await fetchOrdersService(status);
    return response.data;
});
export const getSingleOrderAction: any = createAsyncThunk("orders/getSingleOrder", async (id: string) => {
    const response = await fetchSingleOrderService(id);
    return response.data;
});
export const changeOrderStatusAction: any = createAsyncThunk(
    "orders/changeOrderStatus",
    async ({ id, status }: any) => {
        const response = await changeOrderStatusService({ id, status });
        return response.data;
    }
);


export const getIncompletedOrdersAndFilter: any = createAsyncThunk("orders/getIncompletedAndFilterOrders", async () => {
    try {
        const response = await fetchOrdersService("incompleted");
        const orders = response.data;
        const filteredOrders = orders?.filter((item: any) => item?.payment === "proforma" || item?.payment === "offer");
        return filteredOrders;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error; // Rzuć błąd, aby mógł być obsłużony w Redux
    }
});